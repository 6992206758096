var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"height":"100%","color":"backgroundColor"}},[_c('v-card',{staticClass:"elevation-1 ma-4 pa-4"},[_c('div',{staticClass:"px-5 py-7"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"my-4",attrs:{"cols":"12","md":"6"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Historique des commandes éditeurs")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})],1)],1),_c('v-col',{staticClass:"my-4",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"ma-0 pa-0 px-4",attrs:{"append-icon":"mdi-magnify","label":"Recherche","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"my-4",attrs:{"cols":"12","md":"6"}},[_c('v-menu',{ref:"menuStartDate",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"280px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mx-4",attrs:{"label":"Debut","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuStartDate),callback:function ($$v) {_vm.menuStartDate=$$v},expression:"menuStartDate"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){_vm.menuStartDate=false}},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)],1),_c('v-col',{staticClass:"my-4",attrs:{"cols":"12","md":"6"}},[_c('v-menu',{ref:"menuEndDate",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"280px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mx-4",attrs:{"label":"Fin","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuEndDate),callback:function ($$v) {_vm.menuEndDate=$$v},expression:"menuEndDate"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){_vm.menuEndDate=false}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},[_c('v-spacer')],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"footer-props":{itemsPerPageOptions: _vm.itemsPerPageOptions},"header-props":{ sortByText: "Trier par"},"headers":_vm.headers,"items":_vm.historique,"single-expand":_vm.singleExpand,"show-expand":"","mobile-breakpoint":"1000","options":_vm.options,"sort-desc":_vm.sortDesc,"expanded":_vm.expanded,"pageCount":_vm.numberOfPages,"server-items-length":_vm.totalHistorique},on:{"update:options":function($event){_vm.options=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status ==='TERMINEE')?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check-circle ")]):_vm._e(),(item.status ==='ECHEC')?_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-close-circle ")]):_vm._e()]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Number(item.total.toFixed(2)))+" € ")]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"ma-0 pa-2 colaps",attrs:{"colspan":"100vw"}},[_c('v-data-table',{staticClass:"ma-0 pa-0",attrs:{"footer-props":{itemsPerPageOptions: _vm.itemsPerPageOptions},"header-props":{ sortByText: "Trier par"},"headers":_vm.headers2,"items":item.bibliotheques,"mobile-breakpoint":"1000","disable-sort":""},scopedSlots:_vm._u([{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"pl-4",staticStyle:{"width":"100px"},attrs:{"type":"number","min":"0","item-key":"item.id","disabled":!(_vm.expanded[0].status === "ECHEC")},on:{"input":function($event){return _vm.NewPrice(item)}},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", _vm._n($$v))},expression:"item.quantity"}})]}},{key:"item.prix",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.prix)+" € ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.total)+" € ")]}},{key:"top",fn:function(){return [_c('v-row',{staticClass:"mx-a my-4",attrs:{"justify":"space-around"}},[_c('BooksTab',{key:item.id,attrs:{"userBooks":item.bibliotheques,"editeur":item.editeur,"disable":!(item.status === "ECHEC"),"action":"ajouter"},on:{"delete":_vm.catchDeleteEmit,"add":_vm.catchAddEmit}}),_c('next-button',{attrs:{"width":"280","color":"primary","text":"Relancer la commande","loading":_vm.loadingRetryCommande,"disable":!(item.status === "ECHEC"),"left":""},on:{"clicked":function($event){return _vm.retryCommande(item.etablissement)}}})],1)]},proxy:true}],null,true)})],1)]}}],null,true)})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }