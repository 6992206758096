<template>
  <v-card
    height="100%"
    color="backgroundColor"
  >

    <v-card class="elevation-1 ma-4 pa-4">
      <!-- Tableau  ------------------------------------------------------------------------------------>
      <div class="px-5 py-7">

        <v-row no-gutters>
          <v-col
            cols="12"
            md="6"
            class="my-4"
          >
            <v-toolbar flat>
              <v-toolbar-title>Historique des commandes éditeurs</v-toolbar-title>
              <v-divider
                class="mx-4"
                inset
                vertical
              ></v-divider>
            </v-toolbar>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="my-4"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Recherche"
              single-line
              hide-details
              class="ma-0 pa-0 px-4"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="my-4"
          >
            <!-- Date picker -->
            <v-menu
              ref="menuStartDate"
              v-model="menuStartDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="280px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="startDate"
                  label="Debut"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  class="mx-4"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="startDate"
                no-title
                scrollable
                @input="menuStartDate=false"
              >

              </v-date-picker>
            </v-menu>
            <!-- Date picker  END -->
          </v-col>

          <v-col
            cols="12"
            md="6"
            class="my-4"
          >
            <!-- Date picker -->
            <v-menu
              ref="menuEndDate"
              v-model="menuEndDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="280px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="endDate"
                  label="Fin"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  class="mx-4"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="endDate"
                no-title
                scrollable
                @input="menuEndDate=false"
              >
                <v-spacer></v-spacer>

              </v-date-picker>
            </v-menu>
            <!-- Date picker  END -->
          </v-col>
        </v-row>

        <v-data-table
          :footer-props="{itemsPerPageOptions}"
          :header-props="{ sortByText: `Trier par`}"
          :headers="headers"
          :items="historique"
          class="elevation-0"
          :single-expand="singleExpand"
          show-expand
          mobile-breakpoint="1000"
          :options.sync="options"
          :sort-desc.sync="sortDesc"
          :expanded.sync="expanded"
          :pageCount="numberOfPages"
          :server-items-length="totalHistorique"
        >
          <template v-slot:[`item.status`]="{ item }">
            <v-icon
              color="success"
              v-if="item.status ==='TERMINEE'"
            >
              mdi-check-circle
            </v-icon>
            <v-icon
              color="error"
              v-if="item.status ==='ECHEC'"
            >
              mdi-close-circle
            </v-icon>
          </template>
          <template v-slot:[`item.total`]="{ item }">
            {{ Number(item.total.toFixed(2)) }} €
          </template>

          <!-- Menu déroulant ------------------------------------------------------------------------------->
          <template
            v-slot:expanded-item="{ item }"
            @click="getCurrentItem(item)"
          >
            <td
              colspan="100vw"
              class="ma-0 pa-2 colaps"
            >
              <v-data-table
                :footer-props="{itemsPerPageOptions}"
                :header-props="{ sortByText: `Trier par`}"
                :headers="headers2"
                :items="item.bibliotheques"
                class="ma-0 pa-0"
                mobile-breakpoint="1000"
                disable-sort
              >
                <template v-slot:[`item.quantity`]="{ item }">
                  <v-text-field
                    v-model.number="item.quantity"
                    @input="NewPrice(item)"
                    type="number"
                    style="width: 100px"
                    min="0"
                    item-key="item.id"
                    class="pl-4"
                    :disabled="!(expanded[0].status === `ECHEC`)"
                  ></v-text-field>
                </template>
                <template v-slot:[`item.prix`]="{ item }">
                  {{item.prix}} €
                </template>
                <template v-slot:[`item.total`]="{ item }">
                  {{item.total}} €
                </template>

                <template v-slot:top>
                  <v-row
                    justify="space-around"
                    class="mx-a my-4"
                  >
                    <BooksTab
                      :userBooks="item.bibliotheques"
                      :editeur="item.editeur"
                      :key="item.id"
                      @delete="catchDeleteEmit"
                      @add="catchAddEmit"
                      :disable="!(item.status === `ECHEC`)"
                      action="ajouter"
                    />
                    <next-button
                      width="280"
                      color="primary"
                      text="Relancer la commande"
                      @clicked="retryCommande(item.etablissement)"
                      :loading="loadingRetryCommande"
                      :disable="!(item.status === `ECHEC`)"
                      left
                    />

                  </v-row>
                </template>
              </v-data-table>
            </td>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </v-card>
</template>


<script>
import AdminService from "./../services/AdminService";
import NextButton from "./../components/Utility/NextButton.vue";
import OrderService from "./../services/OrderService";
import BooksTab from "./../components/Utility/BooksTab.vue";
export default {
  components: {
    NextButton,
    BooksTab,
  },
  data: () => ({
    searchTimer: undefined,
    search: "",
    singleExpand: true,
    itemsPerPageOptions: [10, 25, 50, 1000],
    page: 1,
    historique: [],
    totalHistorique: 0,
    numberOfPages: 0,
    pageCount: 0,
    loading: false,
    loadingRetryCommande: false,
    size: 10,
    sortDesc: true,
    options: {},
    headers: [
      { text: "ID Commande", value: "commandeId", align: "start" },
      { text: "Statut", value: "status" },
      { text: "Date", value: "date" },
      { text: "Etablissement", value: "etablissement" },
      { text: "Editeur", value: "editeur" },
      { text: "Total", value: "total", sortable: false },
      { text: "Message", value: "message", sortable: false },
    ],
    headers2: [
      {
        text: "Nom",
        value: "name",
        align: "center",
        class: "lightprimary primary--text ",
      },
      {
        text: "Référence Divalto",
        value: "refOffre",
        class: "lightprimary primary--text",
      },
      {
        text: "Prix",
        value: "prix",
        class: "lightprimary primary--text ",
      },
      {
        text: "Quantité",
        value: "quantity",
        class: "lightprimary primary--text",
      },
      {
        text: "Total",
        value: "total",
        class: "lightprimary primary--text",
        width: "20%",
      },
    ],
    expanded: [],
    endDate: new Date(Date.now()).toISOString().substr(0, 10),
    startDate: new Date(
      new Date(Date.now()).setMonth(new Date(Date.now()).getMonth() - 2)
    )
      .toISOString()
      .substr(0, 10),
    menuStartDate: false,
    menuEndDate: false,
  }),
  computed: {},
  mounted() {
    if (this.$route.query.search) {
      this.search = this.$route.query.search;
    }
  },
  watch: {
    search: function () {
      if(this.searchTimer) {
        clearTimeout(this.searchTimer)
      }
      this.searchTimer = setTimeout(() => {
        this.options.page = 1;
        this.initialize();
      }, 1000)
    },
    options: {
      handler() {
        this.initialize();
      },
    },
    startDate: {
      handler() {
        if (this.startDate) {
          this.initialize();
        }
      },
    },
    endDate: {
      handler() {
        if (this.endDate) {
          this.initialize();
        }
      },
    },
    deep: true,
  },
  methods: {
    initialize() {
      this.loading = true;
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      let pageNumber = page - 1;
      AdminService.getHistorique(
        pageNumber,
        itemsPerPage,
        sortBy,
        sortDesc,
        this.search,
        this.startDate,
        this.endDate
      )
        .then((element) => {
          this.loading = false;
          this.historique = element.data.content;
          this.totalHistorique = element.data.totalElements;
          this.numberOfPages = element.data.totalPages;
          this.historique.forEach((element, id) => {
            this.historique[id].date = new Date(
              this.historique[id].date
            ).toLocaleString("fr");

            this.historique[id].total = this.historique[
              id
            ].bibliotheques.reduce((acc, value) => {
              return acc + value.prix * value.quantity;
            }, 0);
          });
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: "Une erreur est survenue",
          });
          this.loading = false;
        });
    },
    retryCommande(idEtablissement) {
      this.loadingRetryCommande = true;

      OrderService.retrySendOrder([...this.expanded], idEtablissement)
        .then(() => {
          this.loadingRetryCommande = false;
          this.$store.dispatch("setSnackbar", {
            color: "succes",
            text: "Commande renvoyée !",
          });
          this.initialize();
        })
        .catch((err) => {
          if (err) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "La commande n'a pas pu etre relancé.",
            });
            this.loadingRetryCommande = false;
          }
        });
    },
    updateTotal(id) {
      this.expanded.bibliotheque[id].total = 110;
    },
    NewPrice(item) {
      item.total = item.quantity * item.prix;
    },
    catchDeleteEmit(payload) {
      this.expanded[0].bibliotheques.splice(
        this.expanded[0].bibliotheques.findIndex(
          (v) => v.refOffreDivalto === payload.book.refOffreDivalto
        ),
        1
      );
    },
    catchAddEmit(payload) {
      const newBook = {};
      newBook.name = payload.book.ouvrage.nom;
      newBook.type = "livre";
      newBook.editeur = payload.book.ouvrage.editeurCatalog.codeEditeur;
      newBook.refOffre = payload.book.refOffreDivalto;
      newBook.refOffreDivalto = payload.book.refOffreDivalto;
      newBook.matiere = payload.book.ouvrage.matiere;
      newBook.prix = payload.book.prixTtc;
      newBook.quantity = 1;
      newBook.total = newBook.quantity * newBook.prix;
      this.expanded[0].bibliotheques.push(newBook);
    },
  },
};
</script>

<style>
.colaps {
  width: 100vw;
}
</style>
